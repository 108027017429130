#star-search {
  width: 50%;
  @media only screen and (max-width: 900px) {
    width: 85%;
    margin-left: 4px;
  }
}

#star-dropdown {
  position: absolute;
  z-index: 1000;
  top: 54px;
  width: 100%;
  height: 68px;
  @media only screen and (max-width: 900px) {
    height: 62px;
  }

  overflow-y: scroll;

  .list-group-item {
    height: 68px;

    .star-logo {
      width: 15%;
    }

    .star-info {
      width: 50%;
    }

    @media only screen and (max-width: 900px) {
      height: 62px;
      .star-name {
        font-size: 10px;
      }
      .star-info {
        width: 75%;
      }
      .star-logo {
        width: 25%;
      }
    }
  }
}
