#date-picker {
  position: fixed;
  padding: 20px;
  z-index: 1;
  border-radius: 8px;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  @media only screen and (max-width: 900px) {
    font-size: 10px;
    padding: 15px 10px;
    max-width: 35vw;
  }

  &.hide-date {
    transform: translateX(150%);
  }

  * {
    cursor: pointer;
  }

}

.react-datepicker {
  background-color: #333333 !important;
  border: none !important;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  border-radius: 10px !important;

  &__header {
    border-radius: 10px 0 0 0 !important;
    border-bottom: #727272 1px solid !important;

    &--time {
      border-radius: 0 10px 0 0 !important;
    }
  }

  &__input-time-container {
    color: white !important;
    background: none !important;
    margin-left: 10px;
  }

  &-time__input {
    background: white !important;
    outline: none !important;
    border: none !important;
    font-weight: 600 !important;
  }

  &__time-container {
    border-left: #727272 1px solid !important;
    border-radius: 0 10px 10px 0 !important;
    background-color: #4c4c4c !important;
  }

  &__time-list, &__time-box, &__time {
    border-radius: 0 0 10px 0 !important;
    background-color: #4c4c4c !important;
  }

  &-time__header, &__current-month, &__day-name {
    color: white !important;
  }

  &__time-list {
    height: 330px !important;
  }

  &__time-list-item {
    line-height: 20px;
  }

  &__day, &__time-list-item {
    color: white !important;
    font-weight: 600 !important;
    border-radius: 10px !important;
    will-change: background-color, color !important;

    &-name {
      color: rgba(255, 255, 255, 0.6) !important;
    }

    &:hover {
      background-color: #ffaa00 !important;
    }

    border: none !important;

    &--keyboard-selected {
      background-color: #333333 !important;
      border: none !important;
      outline: none !important;
    }

    &--selected {

      color: white !important;
      background-color: #ffaa00 !important;
      border: none !important;
      outline: none !important;
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
      transition: background-color 0.3s ease-in;

      &:hover {
        background-color: #ffaa00 !important;
        color: white !important;
      }

    }
  }

  &__header {
    background-color: #333333 !important;
  }

}

#night-btn {
  position: absolute;
  top: 0;
  right: 0;
  @media only screen and (max-width: 900px) {
    font-size: 10px;
  }
}

.picker {
  background-color: transparent !important;
  width: 100%;

  &:focus {
    border: none !important;
    outline: none !important;
  }
}
