.twin-container {
  position: fixed;
  top: 16vh;
  left: 0;
  padding: 10px 20px;
  border-radius: 10px;
  transition: transform 0.3s ease-in;
  transform: translateX(0) translateY(-5%);
  height: 400px;
  cursor: pointer;
  color: white;
  width: 42rem;
  @media only screen and (max-width: 900px) {
    width: 95vw;
    top: 14vh;
    height: 300px;
    padding: 10px 15px;
  }

  &.is-hidden {
    transform: translateX(-90%) translateY(-5%);
    @media only screen and (max-width: 900px) {
      transform: translateX(-85%) translateY(-5%);
    }
    height: 300px;
  }
}

#hidden-title {
  position: absolute;
  right: 20px;
  top: 50%;
  color: white;
  transform: translateY(-100%) rotateZ(-90deg);
  transform-origin: 100% 100%;
  @media only screen and (max-width: 900px) {
    right: 15px !important;
    font-size: 12px;
  }
}
