@keyframes easeIn {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

#geo-card {
  position: fixed;
  bottom: 40%;
  left: 5%;
  z-index: 10000;
  width: 300px;
  min-height: 120px;
  animation: easeIn 0.2s ease-in;
  @media only screen and (max-width: 900px) {
    width: 100%;
    left: 0;
    bottom: unset;
    top: 0;
    .card-body {
      .h5 {
        margin: 0;
      }
    }
  }

  #close-loc-btn {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

#geocoder-container > div > input {
  &:focus, &:active {
    outline: none !important;
  }
}
