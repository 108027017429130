#selected-panel {
  width: 40rem;
  transition: transform 0.3s ease-in-out;
  max-height: 600px;
  min-height: 300px;
  position: fixed;
  z-index: 100;
  padding: 20px 20px 60px;
  left: 0;
  top: 17vh;
  border-radius: 0 10px 10px 0;
  transform: translateX(-100%);

  &.hide {
    @media only screen and (min-width: 900px) {
      transform: translateX(-90%);
      &:hover {
        transform: translateX(-85%);
      }
    }
    transform: translateX(-85%);

    &.full-hide {
      transform: translateX(-100%);
    }

    min-height: 350px;
    height: 350px;
    max-height: 350px;
    @media only screen and (max-width: 900px) {
      min-height: 300px;
      height: 300px;
      max-height: 300px;
    }
    cursor: pointer;
  }

  &.open {
    transform: translateX(0);
  }

  @media only screen and (max-width: 900px) {
    width: 90vw;
    padding: 10px 10px 40px;
  }
}

.toggle-btn {
  position: fixed;
  bottom: 10px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
  @media only screen and (max-width: 900px) {
    font-size: 20px;
    right: 15px;
  }
}

#hidden-title {
  position: absolute;
  right: 20px;
  top: 50%;
  color: white;
  transform: translateY(-100%) rotateZ(-90deg);
  transform-origin: 100% 100%;

  &.many {
    font-size: 12px;
  }

  @media only screen and (max-width: 900px) {
    right: 15px !important;
    font-size: 12px;
    &.many {
      font-size: 10px;
    }
  }
}
