#toolbar {
  position: fixed;
  padding: 0 20px;
  z-index: 1;
  top: 0;
  left: 10%;
  height: 64px;
  width: 80%;
  border-radius: 0 0 8px 8px;
  @media only screen and (max-width: 900px) {
    width: 100%;
    left: 0;
  }
  transform: translateY(0);
  transition: transform 0.2s ease-in-out;

  &.hide-nav {
    transform: translateY(-100%);
  }
}

.brand-logo {
  @media only screen and (min-width: 900px) {
    width: 25%;
  }
}
