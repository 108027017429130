#language-selector {
  position: fixed;
  bottom: 10px;
  left: 15%;
  @media only screen and (max-width: 900px) {
    left: 10px;
    bottom: 50px;
  }

  button {
    height: 36px;
  }
}
