@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~bootstrap-icons/font/bootstrap-icons.css';

html {
  overflow-y: visible;
}

@keyframes easeIn {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.loader {
  position: absolute;
  z-index: 10000;
  background-color: #333;
  height: 100%;
  width: 100%;
  transition: display 0.5s ease-in-out;

  &.loaded {
    display: none !important;

    * {
      display: none !important;
    }
  }

  &.loading {
    display: block;

    img, h4 {
      animation: easeIn 0.2s ease-in;
    }

    h4 {
      margin-top: 0;
      transition: display 0.3s ease-in-out;
      display: block;
    }
  }
}

.star-color {
  color: #ffaa00;
}

.star-color-bg {
  background-color: #ffaa00;
}

.text-shadow {
  text-shadow: 0.02em 0.02em 0.01em rgba(0, 0, 0, 0.2);
}

.mat-pink {
  color: rgb(227, 43, 106);
}

@keyframes jello {
  11.1% {
    transform: none
  }
  22.2% {
    transform: skewX(-12.5deg) skewY(-12.5deg)
  }
  33.3% {
    transform: skewX(6.25deg) skewY(6.25deg)
  }
  44.4% {
    transform: skewX(-3.125deg) skewY(-3.125deg)
  }
  55.5% {
    transform: skewX(1.5625deg) skewY(1.5625deg)
  }
  66.6% {
    transform: skewX(-0.78125deg) skewY(-0.78125deg)
  }
  77.7% {
    transform: skewX(0.390625deg) skewY(0.390625deg)
  }
  88.8% {
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg)
  }
  100% {
    transform: none
  }
}

.jello {
  animation: jello 2s infinite;
  transform-origin: center
}

.mat-pink-bg {
  background-color: rgb(227, 43, 106);
}

.mat-pink-light {
  color: #ff7597;
}

.mat-pink-light-bg {
  background-color: #ff7597;
}

.light-blue-color {
  color: rgb(3, 169, 244);
}

html, body, #root {
  overflow-y: visible !important;
  overflow-x: visible;
  position: fixed !important;
  width: 100%;
  height: 100%;
  padding: 0 !important;
  font-size: 14px;
}

.click-through {
  pointer-events: none;
}

.get-click {
  pointer-events: all;
}

.menu__content {
  background-color: transparent !important;
}

#stel {height: 100%; width: 100%; position: absolute;}

#stel-canvas {z-index: -10; width: 100%; height: 100%;}
