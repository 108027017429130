.title {
  @media only screen and (max-width: 900px) {
    font-size: 24px !important;
  }
}

.summary {
  @media only screen and (max-width: 900px) {
    font-size: 12px;
  }
}

#stats {
  border-radius: 10px;
  padding: 20px;
  width: 70%;
  background-color: rgba(255, 255, 255, .1);
  transition: background-color .3s ease-in;
  @media only screen and (max-width: 900px) {
    width: 100%;
  }

  &.is-hidden {
    background-color: transparent;
  }

}

#card-img {
  @media only screen and (max-width: 900px) {
    height: 30px;
    width: 30px;
  }
}

.subtitle {
  @media only screen and (max-width: 900px) {
    font-size: 14px;
  }
}

.stat {

  .name {
    width: 40%;
  }

  .value {
    width: 60%;
  }
}

.stat, .info {
  font-size: 14px;
}

.info {
  @media only screen and (max-width: 900px) {
    font-size: 10px;
  }
}

.stat {
  @media only screen and (max-width: 900px) {
    font-size: 8px;
  }
}


.card-text {
  font-size: 12px;
  line-height: 1.2em !important;
  @media only screen and (max-width: 900px) {
    font-size: 10px;
    line-height: 1.1em !important;
  }
}

#image-container-un {
  width: 25%;
  @media only screen and (max-width: 900px) {
    position: absolute;
    right: 5px;
    top: 10px;
    width: auto;
  }
}
