#bottomBar {
  position: fixed;
  bottom: 5%;
  left: 30%;
  width: 40%;
  @media only screen and (max-width: 900px) {
    width: 70%;
    left: 15%;
    bottom: 14%;
  }
}
