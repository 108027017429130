:host {
  display: block;
  position: relative;
}

.personal-message {
  font-size: 12px;
  @media only screen and (max-width: 900px) {
    font-size: 10px;
  }
}

.title {
  @media only screen and (max-width: 900px) {
    font-size: 24px !important;
  }
  font-weight: 600 !important;
}

.twin-badge {
  @media only screen and (max-width: 900px) {
    font-size: 12px;
  }
}

#stats {
  &.is-hidden {
    background-color: transparent;
  }
}

#card-img {
  @media only screen and (max-width: 900px) {
    height: 30px;
    width: 30px;
  }
}

#image-container {
  @media only screen and (max-width: 900px) {
    position: absolute;
    left: 5px;
    top: 5px;
  }
}

.subtitle {
  @media only screen and (max-width: 900px) {
    font-size: 14px;
  }
}

.card-text {
  font-size: 12px;
  line-height: 1.2em !important;
  @media only screen and (max-width: 900px) {
    font-size: 10px;
    line-height: 1.1em !important;
  }
}

.panel-star-icon {
  position: fixed;
  top: 20px;
  right: 20px;
  font-size: 24px;
  @media only screen and (max-width: 900px) {
    top: 5px;
    right: 10px;
  }
}

.share-link {
  position: absolute;
  bottom: 20px;
  left: 35px;
  @media only screen and (max-width: 900px) {
    bottom: 25px;
    left: 25px;
  }
}

.stat {

  .name {
    width: 40%;
  }

  .value {
    width: 60%;
  }
}

.stat, .info {
  font-size: 14px;
}
.info {
  @media only screen and (max-width: 900px) {
    font-size: 10px;
  }
}
.stat {
  @media only screen and (max-width: 900px) {
    font-size: 8px;
  }
}
