.bottom-button {
  width: 42px;
  height: 42px;
  position: relative;
  display: inline-block;
  user-select: none;
  cursor: pointer;
  transition: opacity 0.1s ease-in;

  &.off {
    opacity: 0.2;

    &:hover {
      opacity: 1;
    }
  }

  &.on {
    &:hover {
      opacity: 0.2;
    }
  }
}
