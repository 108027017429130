#language-dropdown {
  position: fixed;
  padding: 20px;
  z-index: 1;
  border-radius: 8px;
  left: 15%;
  bottom: 10px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  @media only screen and (max-width: 900px) {
    left: 10px;
    bottom: 50px;
    font-size: 10px;
    padding: 15px 10px;
    max-width: 35vw;
  }

  * {
    cursor: pointer;
  }

  #close-btn {

  }

}
